import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Accordion, Card, Button } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Termin from "../components/termin"

const TerminePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allTermineYaml {
        edges {
          node {
            date
            title
            location
            year
          }
        }
      }
    }
  `)

  const termineByYear = {}

  for (const edge of data.allTermineYaml.edges) {
    const termin = edge.node
    if (!termineByYear[termin.year]) termineByYear[termin.year] = [termin]
    else termineByYear[termin.year] = [...termineByYear[termin.year], termin]
  }

  const sortedYears = Object.keys(termineByYear).sort((a, b) => b - a)

  return (
    <Layout>
      <SEO title="Termine" lang="de" />
      <Container>
        <h1 className="h3">Termine</h1>
        <hr className="featurette-divider" />

        <Accordion defaultActiveKey={sortedYears[0]}>
          {sortedYears.map(year => (
            <Card key={year}>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={year}>
                  {year}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={year}>
                <>
                  {termineByYear[year].map((termin, i) => (
                    <Termin termin={termin} key={i} />
                  ))}
                </>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Container>
    </Layout>
  )
}

export default TerminePage
