import React from "react"

import terminStyles from "./termin.module.css"

const Termin = ({ termin }) => (
  <div className={terminStyles.termin}>
    <h5>{termin.title}</h5>
    <p className="text-muted mb-0">
      {termin.date} ({termin.location})
    </p>
  </div>
)

export default Termin
